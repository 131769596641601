<template>
  <div class="box">
    <div class="btn">
      <!-- 开灯，关灯，上升高度，下降高度，停止升降，烘干，风干，消毒 -->
      <div class="r">
        <el-button type="primary" @click="op('开灯')">开灯</el-button>
        <el-button type="primary" @click="op('关灯')">关灯</el-button>
        <el-button type="primary" @click="op('停止升降')">停止升降</el-button>
        <el-button type="primary" @click="op('烘干开')">烘干开</el-button>
        <el-button type="primary" @click="op('烘干关')">烘干关</el-button>
        <el-button type="primary" @click="op('风干开')">风干开</el-button>
      </div>
      <div class="s" style="padding-top: 10px">
        <el-button type="primary" @click="op('风干关')">风干关</el-button>
        <el-button type="primary" @click="op('消毒开')">消毒开</el-button>
        <el-button type="primary" @click="op('消毒关')">消毒关</el-button>
        <el-dropdown style="margin:0 5px" split-button type="primary" @click="opp('上升',100)" @command="ahight"
          >升晾衣架
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="20">20%</el-dropdown-item>
            <el-dropdown-item command="40">40%</el-dropdown-item>
            <el-dropdown-item command="60">60%</el-dropdown-item>
            <el-dropdown-item command="80">80%</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown split-button type="primary" @click="opp('下降',100)" @command="alow"
          >降晾衣架
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="20">20%</el-dropdown-item>
            <el-dropdown-item command="40">40%</el-dropdown-item>
            <el-dropdown-item command="60">60%</el-dropdown-item>
            <el-dropdown-item command="80">80%</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="k" style="padding-top: 10px">
        
      </div>
    </div>
    <div class="slide">
      <!-- <el-form label-width="90px" label-position="left">
        <el-form-item label="上升高度">
          <el-slider v-model="hight" :step="10" @change="op('上升')" show-stops> </el-slider>
        </el-form-item>
        <el-form-item label="下降高度">
          <el-slider v-model="low" :step="10" show-stops @change="op('下降')"> </el-slider>
        </el-form-item>
      </el-form> -->
      <el-row>

      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hight: 0,
      low: 0,
      devState: "",
    };
  },
  props: {
    id: String | Number,
  },
  methods: {
    ccc(e){
      console.log(e)
    },
    opp(type,val){
      
      this.devState = type
      if(type == '上升') {
        this.hight = val
      }else{
        this.low = val
      }
      this.charge();
    },
    op(e) {
      console.log(e);
      this.devState = e;
      this.charge();
    },
    handleClick(e) {
      console.log(e);
    },
    ahight(val){
      this.devState = '上升'
      this.hight = val
      this.charge()
    },
    alow(val){
      this.devState = '下降'
      this.low = val
      this.charge()
    },
    charge() {
      console.log(this.devState, this.low);
      let devPercentage = 0;
      console.log(this.devState);
      switch (this.devState) {
        case "上升":
          console.log(this.hight);
          devPercentage = this.hight;
          break;
        case "下降":
          devPercentage = this.low;
          break;
      }
      let data = {
        devPercentage,
        devState: this.devState,
        devId: this.id,
      };
      this.$axios({
        method: "post",
        url: "/common2/devices/operate",
        data,
      }).then((res) => {
        console.log(res);
        this.$emit("save");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>